@font-face {
  font-family: Tornado;
  src: url(./assets/fonts/Tornado/TornaCyrReg.otf);
  font-display: swap;
}

@font-face {
  font-family: TrajanPro3;
  src: url(./assets/fonts/TrajanPro3/TrajanPro3-Regular.otf);
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url(./assets/fonts/Roboto-Regular.ttf);
  font-display: swap;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-family: inherit;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  line-height: 1.1;
  font-family: TrajanPro3;
}

body {
  margin: 0;
  color: #333;
  line-height: 1.42857143;
  font-size: 14px;
  background-color: #fafafa;
  font-family: Tornado;
}

#root {
  max-height: 100vh;
  overflow: auto;
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

p {
  margin: 0;
}

.openPresentation {
  background-color: rgba(26, 38, 78, .9);
  border: none;
  color: #d9d9d9;
  display: block;
  font-size: 25px;
  font-weight: 300;
  letter-spacing: 2.1px;
  margin: 40px auto;
  padding: 10px 20px;
  text-transform: uppercase;
  white-space: nowrap;
  width: fit-content;

  @media (max-width: 768px) {
    font-size: 20px !important;
  }
}

.grecaptcha-badge {
  z-index: 25;
}

.grecaptcha-badge {
  display: none !important;
}