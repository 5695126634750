.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  align-items: end;
  justify-items: center;
  margin-top: 20px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.item {
  text-align: center;
  text-transform: uppercase;
  color: #0c476c;
  margin: 13px 0px 0px;
  max-width: 326px;
  opacity: 1;
  transition: 0.2s ease-out;

  &:hover {
    opacity: 0.95;
    transition: 0.15s ease-in;
    color: #333;
  }
}

.itemTitle {
  letter-spacing: 3px;
  line-height: 20px;
  font-size: 20px;
}

.itemImageBlock {
  width: 326px;
  height: 113px;
  background-size: 326px;
  margin: 15px 0px 0px;
  position: relative;
}

.itemImage {
  object-fit: cover;
}

.titlePlaceholder {
  height: 20px;
  width: 80%;
  margin: 17px auto;
}