.pagination {
    display: flex;
    margin: 40px 0 10px;
}

.item {
    height: 35px;
    width: 35px;
    border: 1px solid #ddd;
    color: #337ab7;
}

.item span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    user-select: none;
    cursor: pointer;
}
.item.active span{
    color: #fff;
    cursor: default;
    background-color: #337ab7;
    border-color: #337ab7;
}
.item.disabled span{
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
}