.overlay {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.75);
  transition: opacity 0.3s, visibility 0s 0.4s;

  &.active {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;

    .wrapper {
      transform: translateX(0px);
    }
  }
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transform: translateX(-250px);
  width: 250px;
  background-color: #fff;
  z-index: 100;
  transition: 0.3s;
  overflow: auto;
}

.logo {
  width: 100%;
  text-align: center;
}

.openBtn {
  position: fixed;
  left: 0;
  top: 25px;
  width: 64px;
  height: 31.5px;
  z-index: 10;
  padding: 6px 8px;
  background-color: #fff;
}

.openBtnSvg {
  width: 100%;
  height: 100%;
  transform: scale(-1);
}

.closeBtn {
  width: 43.5px;
  height: 43.5px;
  padding: 12px;
  background-color: #fff;
  position: absolute;
  top: 5px;
  right: 5px;
}

.divider {
  height: 1px;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.12);
  border: none;
}

.linksList {
  padding-top: 8px;
  padding-bottom: 8px;
  text-transform: uppercase;
}

.listItem {
  padding: 12px 16px;

  >a {
    display: block;
    font-size: 13px;
    line-height: 1.5;
    font-family: Roboto, sans-serif;
    width: 100%;
  }
}

.categoryPlaceholder {
  height: 20px;
  width: 90%;
}