.grid {
  margin-top: 52px;
  color: #000;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: relative;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
}

.item {
  position: relative;
  transition: 0.1s;

  &:hover {
    opacity: 0.9;
  }
}

.itemLink {
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    flex-direction: row;
    gap: 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
}

.itemImageBlock {
  width: 360px;
  height: 360px;
  position: relative;

  @media (max-width: 1200px) {
    width: 300px;
    height: 300px;
  }

  @media (max-width: 768px) {
    width: 320px;
    height: 320px;
  }
}

.itemImage {
  object-fit: cover;
}

.itemBody {
  width: 360px;

  @media (max-width: 1200px) {
    flex: 1;
    position: relative;
  }

  @media (max-width: 768px) {
    width: 320px;
  }
}

.itemTitle {
  font-size: 24px;
  margin-bottom: 10px;

  @media (max-width: 1200px) {
    margin-top: 0;
  }

  @media (max-width: 768px) {
    margin-top: 24px;
  }
}

.placeholderTitle {
  margin-top: 24px;
  height: 24px;
  margin-bottom: 10px;

  @media (max-width: 1200px) {
    margin-top: 0;
  }

  @media (max-width: 768px) {
    margin-top: 24px;
  }
}

.itemShort {
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }

  * {
    font-family: inherit !important;
    font-size: 16px !important;
  }
}

.placeholderText {
  height: 60px;
  margin-bottom: 20px;
}

.itemTime {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #aaaaaa;
  font-size: 0.85rem;
  text-align: left;
}