.title {
  color: #2c2f78;
  padding: 0px 20px;
  padding-top: 10px;
  border-left: 3px solid #1b274c;
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 36px;
  font-weight: 400;

  @media (max-width: 550px) {
    font-size: 25px;
  }
}