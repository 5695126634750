.wrapper {
  margin-top: 94px;
  margin-bottom: 100px;

  @media (max-width: 500px) {
    margin-top: 60px;
  }
}

.title {
  margin-top: 90px;
  font-size: 42px;
  font-family: TrajanPro3;
  font-weight: normal;
  color: #3f3f3f;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  padding-bottom: 25px;
  background: url(../../../assets/image/short-line.png) center bottom no-repeat;

  @media (max-width: 500px) {
    font-size: 32px;
  }
}