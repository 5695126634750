.shopItem {
  width: 196px;

  @media (max-width: 992px) {
    width: 150px;
  }
}

.imagePlaceholder {
  height: 196px;

  @media (max-width: 992px) {
    height: 150px;
  }
}

.itemDescription {
  background-color: #f3f3f3;
  padding: 6px 15px 10px;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.itemTitlePlaceholder{
  height: 19px;
  width: 85%;
}

.itemTextPlaceholder{
  height: 30px;
  width: 85%;
}