.navigation {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-items: center;
  grid-gap: 20px;
  margin: 0 auto;
  height: 100%;
  width: 60%;

  @media (max-width: 1200px) {
    width: 100%
  }
}

.link {
  color: #d9d9d9;
  font-size: 25px;
  text-transform: uppercase;
  letter-spacing: 2.1px;
  white-space: nowrap;
  color: #d9d9d9;
  text-decoration: none;
}

.activeLink {
  text-decoration: underline;
}