.wrapper {
  position: relative;
  height: 100vh;
}

.img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.logo {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  margin-top: 30px;
  user-select: none;
}

.nav {
  position: absolute;
  bottom: 0;
  z-index: 2;
  background-color: rgba(26, 38, 78, 0.9);
  width: 100%;
  height: 86px;

  @media (max-width: 992px) {
    display: none;
  }
}