.wrapper {
  padding: 50px;
  color: #000;
  text-align: center;
  min-height: 100vh;
}

.title {
  margin: 0 0 40px;
  font-family: Tornado;
  font-size: 36px;
  color: #2c2f78;
}

.text {
  margin: 0 0 20px;
  font-size: 16px;
  line-height: 26px;
}

.boldText {
  color: #2c2f78;
  margin-left: 5px;
}