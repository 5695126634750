.wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 10%);
  position: relative;
  z-index: 1;
  color: #000;
  background-color: #fff;
  margin-top: 12px;
  margin-bottom: 12px;

  &:hover {
    opacity: 0.90;
  }

  @media (max-width: 680px) {
    grid-template-columns: 1fr;
  }
}

.imgWrapper {
  width: 300px;
  height: auto;
  border-radius: 8px 0 0 8px;
  padding-top: 0;
  position: relative;

  @media (max-width: 680px) {
    width: 100%;
    height: 300px;
  }
}

.img {
  top: 0;
  left: 0;
  position: absolute;
  object-fit: cover;
  object-position: top left;
}

.body {
  padding: 18px 20px;
}

.tag {
  background-color: #5bc0de;
  display: inline-block;
  padding: 3px 8.5px 4px;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3.5px;
  margin-right: 5px;
}

.title {
  font-size: 30px;

  @media (max-width: 680px) {
    font-size: 25px;
  }
}

.time {
  margin-top: 18px;
  font-size: 12px;
  color: #AAA;
}

.titlePlaceholder{
  height: 66px;
  margin: 25px 0;
}

.shortPlaceholder{
  height: 60px;
  margin-bottom: 18px;
}