.shops {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 18px;
  height: max-content;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 992px) {
    order: 1;
    grid-template-columns: repeat(4, 1fr);
    width: max-content;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.shopItem {
  display: flex;
  flex-direction: column;
  width: 196px;

  @media (max-width: 992px) {
    width: 150px;
  }
}

.itemImgLink {
  display: block;
  height: 196px;
  border: 1px solid #969696;

  @media (max-width: 992px) {
    height: 150px;
  }
}

.itemImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.itemDescription {
  background-color: #f3f3f3;
  padding: 6px 15px 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.itemTitle {
  text-overflow: clip;
  overflow-x: hidden;
  font-size: 18px;
  line-height: 24px;
  color: #000;
  margin: 0 0 5px;

  &:hover {
    color: inherit;
  }
}

.itemText {
  font-size: 13px;
  line-height: 14px;
  color: #8c8c8c;
}