.mainWrapper {
  display: flex;
  gap: 40px;
  padding-bottom: 50px;

  @media (max-width: 992px) {
    flex-direction: column;
    gap: 20px;
  }

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 550px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}