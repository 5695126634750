.wrapper {
  position: relative;
  width: 100%;
  height: 260px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-x: center;
  background-position-y: -42%;
  background-image: url(../../../assets/image/parallax1.jpg);
  color: #fff;

  @media (max-width: 542px) {
    height: auto;
  }
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 38, 78, 0.8);
  padding: 0;

  @media (max-width: 542px) {
    padding: 20px 0;
    position: initial;
    height: inherit;
  }
}

.items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80%;
  margin: 20px auto 0 auto;
  align-items: center;
  justify-items: center;

  @media (max-width: 992px) {
    width: 100%;
  }

  @media (max-width: 542px) {
    grid-template-columns: 1fr;
    margin-bottom: 20px;
    grid-row-gap: 15px;
  }
}

.time {
  font-size: 22px;
}

.contact {
  display: grid;
  grid-gap: 12px;
}

.feedback {
  text-align: center;
  border-radius: 10px;
  background-color: #ffffff;
  color: #2c2f78;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 5px 10px;
}

.phone {
  font-size: 28px;
  text-align: center;
}

.social {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-items: center;
}

.socialLink {
  opacity: 0.85;

  &:hover {
    opacity: 1;
  }
}

.bottom {
  font-size: 22px;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 20px;

  @media (max-width: 542px) {
    position: relative;
    bottom: initial;
  }
}