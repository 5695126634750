.titlePlaceholder {
  height: 38px;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 50%;

  @media (max-width: 550px) {
    height: 27px;
  }
}

.textPlaceholder {
  height: 80px;
  margin-bottom: 10px;
}