.wrapper {
  padding-bottom: 50px;
}

.text p {
  margin-bottom: 10px;
}

.italicText {
  color: #2c2f78;
  font-style: italic;
  margin: -10px 0 25px 20px;
  font-size: 22px;

  * {
    background-color: inherit !important;
  }

  @media (max-width: 768px) {
    font-size: 18px !important;
  }
}

.subCatTitle {
  text-transform: uppercase;
  text-align: center;
  margin-top: 30px;
  color: #2c2f78;
  font-size: 30px;

  @media (max-width: 550px) {
    font-size: 25px;
  }
}

.subCatWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  align-items: end;
  justify-items: center;
  margin-top: 20px;

  @media (max-width: 1199px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.subCatLink {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 20px;
  font-size: 20px;
  color: #0c476c;
  margin: 13px 0px 0px;
  max-width: 326px;
  transition: 0.2s ease-out;
}

.subCatImg {
  width: 326px;
  height: 113px;
  background-size: 326px;
  background-position: center;
  background-repeat: no-repeat;
  margin: 15px 0px 0px;
  position: relative;
}

.social {
  display: flex;
  gap: 5px;
  justify-content: flex-end;
}

.bottomImgBlock {
  margin-top: 15px;
  height: 400px;
  width: 100%;
}

.bottomImg {
  object-fit: cover;
}