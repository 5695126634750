.searchInput {
  width: 100%;
  height: auto;
  padding: 1px 12px 0;
  border-radius: 0;
  display: block;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  }
}

.searchForm {
  position: relative;
}

.searchBtn {
  position: absolute;
  top: 4px;
  right: 4px;
  height: 14px;
  padding: 0;
  width: 14px;
  cursor: pointer;
}

.searchIcon{
  width: 100%;
  height: 100%;
}