.wrapper {
  padding-bottom: 50px;
}

.title {
  margin-bottom: 25px;
}
.searchLoading{
  margin-top: 35px;
  min-height: 50vh;
  text-align: center;
}

.result {
  margin-top: 35px;
  min-height: 50vh;
}

.shops {
  margin-top: 35px;
}