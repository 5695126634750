.wrapper {
  padding-top: 20px;
  padding-bottom: 50px;
}

.title {
  margin-bottom: 30px;
}

.linkBack {
  font-size: 17px;
  color: #7d7d7d;
  line-height: 19px;
  text-decoration: underline;
  display: block;
  position: relative;
  margin-bottom: 25px;
  padding-left: 15px;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: 0;
    width: 11px;
    height: 21px;
    background-image: url(../../../assets/image/arrow.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.linkBackPlaceholder {
  width: 70px;
  height: 19px;
}

.logoWrapper {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  margin-bottom: 30px;
  width: 200px;
  height: 200px;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.info {
  display: flex;
  justify-content: space-between;
  padding: 12px 48px 10px;
  border-top: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;

  @media (max-width: 575px) {
    padding: 12px 20px 10px;
  }
}

.infoPlaceholder {
  height: 26px;
  width: 100%;
}

.category {
  font-size: 19px;
  color: #2a355a;
  line-height: 26px;
}

.floor {
  font-size: 17px;
  font-weight: bold;
  color: #000;
  line-height: 24px;
  text-transform: lowercase;
}

.workTimeWrapper {
  padding: 21px 48px 30px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c2c2c2;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    padding: 20px;
  }

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

.workTimePlaceholder {
  height: 45px;
  width: 100%;
}

.workTime {
  position: relative;
  margin: 0;
  padding-left: 50px;
  font-size: 16px;
  color: #0c1010;
  text-transform: uppercase;

  ::before {
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    width: 36px;
    height: 36px;
    background-image: url(../../../assets/image/watch.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.site {
  font-size: 21px;
  color: #0c1010;
  line-height: 31px;
  text-decoration: underline;
  position: relative;
  padding-top: 15px;
  padding-right: 50px;

  &::before {
    content: "";
    position: absolute;
    top: 5px;
    left: -50px;
    width: 41px;
    height: 41px;
    background-image: url(../../../assets/image/bag.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    @media (max-width: 575px) {
      top: 0;
    }
  }

  @media (max-width: 992px) {
    margin-left: 90px;
  }

  @media (max-width: 768px) {
    padding-right: 0;
    margin-left: 0;
  }

  @media (max-width: 575px) {
    margin-left: 45px;
  }
}

.description {
  padding: 17px 48px 7px;
  font-size: 20px;
  color: #0c1010;
  line-height: 28px;

  @media (max-width: 575px) {
    padding: 17px 15px 7px;
  }
}

.descriptionPlaceholder {
  height: 140px;
}

.social {
  display: flex;
  gap: 7px;

  @media (max-width: 768px) {
    margin-top: 30px;
    width: 100%;
    justify-content: center;
  }

  @media (max-width: 575px) {
    margin-top: 30px;
  }
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 50px 20px 30px;
  justify-content: center;
}

.galleryImageBlock {
  width: 320px;
  height: 320px;
  cursor: pointer;
}

.galleryImage {
  object-fit: cover;
}

.galleryItem:hover {
  opacity: 0.95;
}

.actions {
  padding: 17px 48px 30px;
  border-bottom: 1px solid #c2c2c2;

  @media (max-width: 575px) {
    padding: 17px 15px 30px;
  }
}

.actionsTitle {
  font-size: 30px;

  @media (max-width: 768px) {
    font-size: 22px;
  }
}

.actionsImage {
  display: block;
  margin-top: 20px;
}