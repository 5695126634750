.categories {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
  height: max-content;
  padding: 10px 20px;
  min-width: 275px;
}

.searchTitle {
  margin: 14px 0 20px;
  font-size: 18px;
  font-family: Tornado;
}

.catButton {
  display: block;
  color: #6a6a6a;
  margin-bottom: 5px;
  background-color: transparent;
  text-align: left;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.catPlaceholder{
  height: 15px;
  margin-bottom: 5px;
  width: 70%;
}
